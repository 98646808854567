
@import url('https://fonts.cdnfonts.com/css/mandalore');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  html {
    font-family:  sans-serif;
  }
}